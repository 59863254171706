import React, {useState, useEffect, useCallback, useContext, useRef} from 'react'
import {debounce, every, get, isEmpty, sumBy} from 'lodash'
import {useHistory} from 'react-router-dom'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import {styled} from '@mui/material/styles'
import {
  ThumbUpAlt as ThumbUpAltIcon,
  Remove as RemoveIcon,
  Add as AddIcon,
  Print as PrintIcon,
  QrCode2Rounded,
} from '@mui/icons-material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import {useReactToPrint} from 'react-to-print'

import DetailView from 'components/DetailView'
import {MembershipType} from 'constant/query_type'
import {ListingStatus} from 'constant/listing_status'
import {ConsignStatus, ModalType} from 'enums'
import {useGlobalStore} from 'provider/global_store/hook'
import {useConsignments, useUser} from 'service/hook'
import {_getStatus, _getValue, _getStatusColor, formatName} from 'util/string_utils'
import {getCurrency} from 'util/model/setting'
import {
  EmptyData,
  LoadingBar,
  SearchToolbar,
  TooltipSnippets,
  CopytLabel,
  CopytStyledButton,
  EllipsisTypography,
  LoadingMessage,
} from 'views_v2/lib/snippets'
import {SearchContext} from 'views_v2/lib/classes'
import {BarcodeLabels} from 'views_v2/lib/components'
import PlatformsModal from './PlatformsModal'
import {ReactComponent as ListingEmptyState} from '../../../../assets/img/mobile-listing-empty.svg'
import BatchesPrint from './BatchesPrint'
import ShippingPrompt from 'views_v2/modules/Shipping/ShippingPrompt'

const filterConsignProductListings = (item) => {
  return {
    ...item,
    product: {
      ...item?.product,
      listings: (item?.product?.listings || []).filter(
        (listing) => listing?.status === ListingStatus.LISTED,
      ),
    },
  }
}

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }),
)

const AccordionSummary = styled((props) => {
  const {expanded, ...otherProps} = props
  return (
    <MuiAccordionSummary
      expandIcon={
        expanded ? (
          <RemoveIcon sx={{fontSize: '0.9rem', ml: 2, mr: 2}} />
        ) : (
          <AddIcon sx={{fontSize: '0.9rem', ml: 2, mr: 1}} />
        )
      }
      {...otherProps}
      {...(expanded ? {'aria-expanded': true} : {'aria-expanded': false})}
    />
  )
})(({theme}) => ({
  width: '100%',
  bgcolor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '&:hover': {
    backgroundColor: 'var(--lighter)',
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: 0,
  borderTop: '1px solid var(--light)',
}))

const Batches = (props) => {
  const history = useHistory()
  const search = useContext(SearchContext)

  const currency = getCurrency()
  const {user} = useUser()
  const {isEnterprise} = useGlobalStore()
  const [statusFilter, setStatusFilter] = useState(null)
  const [storeName, setStoreName] = useState(null)
  const [isPrintReady, setIsPrintReady] = useState(false)
  const [printData, setIsPrintData] = useState([])
  const [payload, setPayload] = useState({
    items: [],
    boxItems: [],
    withdraw: [],
    paymentData: {consignmentBatchId: null, count: 0},
  })

  const [modalType, setModalType] = useState(ModalType.UNDEFINED)
  const [expanded, setExpanded] = useState(false)
  const [isPendingItems, setIsPendingItems] = useState(false)

  const {
    consignmentBatch,
    refetchConsignmentBatch,
    isLoading,
    isFetching,
    isLoadingConsignmentBatch,
    isFetchingConsignmentBatch,
    postListAll,
    postList,
  } = useConsignments()
  const [selectionModel, setSelectionModel] = useState([])
  const [optimisticQueuedItems, setOptimisticQueuedItems] = useState([])
  const [optimisticQueuedBatches, setOptimisticQueuedBatches] = useState([])
  const [selectBatchIdToListAll, setSelectBatchIdToListAll] = useState('')
  const [consignmentBatchItems, setConsignmentBatchItems] = useState([])
  const componentRef = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)

  // For Barcodes
  const [isBarcodeLabelReady, setIsBarcodeLabelReady] = useState(false)
  const [barcodeTemplates, setBarcodeTemplates] = useState([])
  const [printSize, setPrintSize] = useState({width: 3.5, height: 1.2})
  const [showBarcodeTemplate, setShowBarcodeTemplate] = useState({})

  const onSearch = useCallback(
    debounce((searchKey) => setSearchText(searchKey), 1000),
    [],
  )
  const {onCreateConsignBatchPayment} = useConsignments()

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    suppressErrors: true,
    documentTitle: `Copyt Batches`,
  })

  const handleGenerateBarcode = useReactToPrint({
    content: () => componentRef.current,
    suppressErrors: true,
    documentTitle: `Copyt - ${printSize.width}x${printSize.height}`,
  })

  const onGenerateBarcodesClicked = async () => {
    setIsBarcodeLabelReady(true)
    setTimeout(() => {
      try {
        handleGenerateBarcode()
      } catch (err) {
        console.error(err)
      }
    }, 500)
  }

  useEffect(() => {
    return () => {
      onSearch.cancel()
    }
  }, [onSearch])

  useEffect(() => {
    if (!isEmpty(optimisticQueuedItems) || !isEmpty(optimisticQueuedBatches)) {
      setOptimisticQueuedItems([])
      setOptimisticQueuedBatches([])
    }
  }, [consignmentBatch])

  const handleConsignmentBatchPayment = async () => {
    const data = {
      consignmentBatchId: payload?.paymentData?.consignmentBatchId,
      amount: payload?.paymentData?.count * 3,
    }

    await onCreateConsignBatchPayment(data)

    setTimeout(() => {
      handlePrint()
      setModalType(ModalType.CONSIGNMENT_BATCH_SHIPPING_PROMPT)
    }, 1000)
  }
  useEffect(() => {
    setIsDisabled(isLoading || isFetching || isFetchingConsignmentBatch)
  }, [isLoading, isFetching, isFetchingConsignmentBatch])

  if (isLoadingConsignmentBatch) return <LoadingBar />
  const hasData = !isEmpty(consignmentBatch)

  const shouldDisableListingStatus = [
    ListingStatus.LISTED,
    ListingStatus.SOLD,
    ListingStatus.PROCESSED,
    ListingStatus.WITHDRAW_APPROVED,
  ]

  const onSaveChanges = (p) => {
    setIsDisabled(true)
    if (p?.onSelectPlatform) {
      if (!isEmpty(selectionModel)) {
        if (selectionModel.length === 1) {
          const items = selectionModel?.map((s) => {
            const product = s?.product
            return {
              price: Number(product?.desiredReturn),
              productId: product?.id,
            }
          })
          setOptimisticQueuedItems((prevState) => {
            const newItems = new Set([...prevState, items[0].productId])
            return Array.from(newItems)
          })

          postList({...items[0], ...p?.onSelectPlatform})
        } else {
          const items = selectionModel
            ?.filter((s) => s?.status !== ConsignStatus.WITHDRAW_APPROVED)
            .map((s) => {
              const product = s?.product
              return {
                listingStatus: ListingStatus.CREATED,
                price: Number(product?.desiredReturn),
                productId: product?.id,
                quantity: product?.quantity,
              }
            })

          setOptimisticQueuedItems((prevState) => {
            const newItems = new Set([...prevState, ...items.map((item) => item.productId)])
            return Array.from(newItems)
          })

          setOptimisticQueuedBatches((prevState) => {
            const newItems = new Set([...prevState, selectBatchIdToListAll])
            return Array.from(newItems)
          })

          postListAll({items, ...p?.onSelectPlatform})
        }
      }
    }

    setTimeout(() => {
      if (isDisabled) {
        setIsDisabled(false)
      }
    }, 1000)
  }

  const batches = isPendingItems
    ? consignmentBatch?.filter((cb) => cb?.status?.toLowerCase() === ListingStatus.CREATED)
    : consignmentBatch

  const isListButtonDisabled = (item) => {
    const shouldShowListButton = get(item, 'product.listings.length', 0) > 1
    return (
      shouldShowListButton ||
      shouldDisableListingStatus.includes(item?.status?.toLowerCase()) ||
      !item?.product?.sku ||
      item?.product?.onQueue ||
      optimisticQueuedItems.includes(item?.product?.id)
    )
  }

  const getListButtonText = (item) => {
    if (!isListButtonDisabled(item) || item?.status === ConsignStatus.WITHDRAW_APPROVED) return 'List'
    if (item?.status === ListingStatus.PROCESSED) return 'Processed'
    return 'Listed'
  }

  const isListAllButtonDisabled = (batch) => {
    return (
      isEmpty(batch?.consigns) ||
      every(
        batch?.consigns,
        (consign) =>
          filterConsignProductListings(consign?.product?.listings)?.length > 1 ||
          shouldDisableListingStatus.includes(consign?.status?.toLowerCase()),
      ) ||
      optimisticQueuedBatches.includes(batch?.id)
    )
  }

  return (
    <SearchContext.Provider
      value={{
        searchText,
        setSearchText,
      }}
    >
      <DetailView.Panel style={{height: '95%'}}>
        {isPrintReady && (
          <Box display="none">
            <BatchesPrint ref={componentRef} printData={printData} />
          </Box>
        )}

        {isBarcodeLabelReady && (
          <div style={{display: 'none'}}>
            <BarcodeLabels
              {...{barcodeTemplates}}
              hasConsignorCode={showBarcodeTemplate?.consignorCode}
              items={selectionModel?.map((s) => s?.product)}
              businessName={user?.businessName || 'Copyt'}
              logo={user?.platform?.logo_url}
              ref={componentRef}
            />
          </div>
        )}
        {hasData || search?.searchText || statusFilter ? (
          <Box height="100%" flex={1}>
            <Stack
              direction="column"
              sx={{
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                border: '3px solid',
                borderBottom: 0,
                borderColor: 'var(--lighter)',
              }}
            >
              <SearchToolbar
                onSearch={onSearch}
                isPendingItems={isPendingItems}
                onIsPendingItems={() => setIsPendingItems(!isPendingItems)}
                showPending={isEnterprise}
                labelStart="Show Pending Only"
                style={{
                  borderTopLeftRadius: '5px',
                  borderTopRightRadius: '5px',
                  borderWidth: '2px',
                  borderColor: 'var(--lighter)',
                }}
              />
              {isDisabled && <LoadingBar type="linear" />}
            </Stack>
            <Stack
              direction="column"
              minHeight="95%"
              maxHeight="95%"
              flex={1}
              bgcolor="white"
              border="1px solid var(--lighter)"
              borderTop={0}
              sx={{overflowY: 'auto'}}
              justifyContent={isLoading ? 'center' : 'flex-start'}
            >
              {isLoading ? (
                <LoadingMessage />
              ) : (
                batches?.map((batch, index) => {
                  const isListAllDisabled = isListAllButtonDisabled(batch) || isDisabled
                  return (
                    <Accordion
                      bgcolor={expanded === `panel${index}` ? 'var(--lighter)' : undefined}
                      key={index}
                      expanded={expanded === `panel${index}`}
                      onChange={handleChange(`panel${index}`)}
                    >
                      <AccordionSummary
                        aria-controls={`panel${index}d-content`}
                        id={`panel${index}d-header`}
                        expanded={expanded === `panel${index}`}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                          flex={1}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            flex={1}
                          >
                            <Box flex={1.5}>
                              <EllipsisTypography>Batch ID: {batch?.id} </EllipsisTypography>
                            </Box>
                            <Box flex={0.8}>
                              <EllipsisTypography>
                                {isEnterprise
                                  ? batch?.consignor?.email || '--'
                                  : batch?.consignee?.businessName || 'Company Name'}
                              </EllipsisTypography>
                            </Box>
                            <Box flex={0.5}>
                              <EllipsisTypography>
                                {isEmpty(batch?.consigns) ? 'No' : batch?.consigns?.length} Items
                              </EllipsisTypography>
                            </Box>
                            <Box flex={0.5}>
                              <EllipsisTypography>
                                {currency.format(
                                  sumBy(batch?.consigns, (item) =>
                                    parseFloat(_getValue(item?.product?.desiredReturn)),
                                  ) || 0,
                                )}
                              </EllipsisTypography>
                            </Box>
                          </Stack>
                          <Divider orientation="vertical" flexItem />
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            px={3}
                            spacing={2}
                          >
                            <Box
                              border={`2px solid ${_getStatusColor(batch?.status)}`}
                              borderRadius={20}
                              p={0.8}
                              width="100px"
                              maxWidth="100px"
                              textAlign="center"
                            >
                              <Typography color={_getStatusColor(batch?.status)} fontWeight={600}>
                                {batch?.status?.toLowerCase() === ListingStatus.CREATED
                                  ? 'Pending'
                                  : batch?.status}
                              </Typography>
                            </Box>
                            {isEnterprise ? (
                              <>
                                <IconButton
                                  disabled={
                                    isEmpty(batch?.consigns) ||
                                    batch?.consigns.every((s) => s?.status === 'withdraw_approved')
                                  }
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()

                                    const model = batch?.consigns?.map((_consign) => {
                                      return {
                                        ..._consign,
                                        product: {
                                          ..._consign.product,
                                          consign: {
                                            consignor: batch.consignor,
                                          },
                                        },
                                      }
                                    })

                                    setSelectionModel(
                                      model?.filter(
                                        (consign) =>
                                          consign?.status?.toLowerCase() !== 'withdraw_approved',
                                      ),
                                    )
                                    setModalType(ModalType.BARCODE_TEMPLATE)
                                  }}
                                >
                                  <QrCode2Rounded sx={{border: '1px solid', borderRadius: '5px'}} />
                                </IconButton>
                                <CopytStyledButton
                                  variant="contained"
                                  color="primary"
                                  disabled={isListAllDisabled}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setModalType(ModalType.LIST_ALL_PLATFORM)
                                    setSelectionModel(
                                      batch?.consigns?.filter((consign) => consign?.product?.sku),
                                    )
                                    setSelectBatchIdToListAll(batch?.id)
                                  }}
                                  sx={{minWidth: '80px', maxWidth: '150px'}}
                                  flex={0.9}
                                >
                                  {isDisabled ? (
                                    <CircularProgress size={24} sx={{color: 'white'}} />
                                  ) : (
                                    'List All'
                                  )}
                                </CopytStyledButton>
                              </>
                            ) : (
                              <IconButton
                                flex={0.5}
                                style={{
                                  padding: '5px',
                                }}
                                disabled={isEmpty(batch?.consigns)}
                                onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()

                                  const items =
                                    batch?.consigns
                                      ?.filter((consign) => !consign.product?.shipments.length)
                                      .map((consign) => consign?.product?.id) || []

                                  setConsignmentBatchItems(items)

                                  setIsPrintData(batch)
                                  setIsPrintReady(true)

                                  const {consignee, consigns, id, isPaid} = batch || {}
                                  const isECG = consignee?.memberships?.some(
                                    (member) => member?.type === MembershipType.ECG,
                                  )
                                  if (isECG && !isPaid) {
                                    if (items.length) {
                                      setModalType(ModalType.ECG_ITEM_SUBMISSION)
                                    }
                                    setPayload({
                                      ...payload,
                                      paymentData: {
                                        count: consigns?.length,
                                        consignmentBatchId: id,
                                      },
                                    })
                                  } else {
                                    setTimeout(() => {
                                      handlePrint()
                                      if (items.length) {
                                        setModalType(ModalType.CONSIGNMENT_BATCH_SHIPPING_PROMPT)
                                      }
                                    }, 500)
                                  }
                                }}
                              >
                                <PrintIcon />
                              </IconButton>
                            )}
                          </Stack>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails style={{overflowX: 'auto', whiteSpace: 'nowrap'}}>
                        <Table>
                          <TableHead
                            style={{
                              backgroundColor: 'var(--lighter)',
                              borderBottom: '1px solid var(--gray)',
                            }}
                          >
                            <TableRow>
                              <TableCell>
                                <CopytLabel>Item</CopytLabel>
                              </TableCell>
                              <TableCell>
                                <CopytLabel>Size</CopytLabel>
                              </TableCell>
                              <TableCell>
                                <CopytLabel>Store</CopytLabel>
                              </TableCell>
                              <TableCell>
                                <CopytLabel>Price</CopytLabel>
                              </TableCell>
                              <TableCell>
                                <CopytLabel>Payout Received</CopytLabel>
                              </TableCell>
                              <TableCell>
                                <CopytLabel>{isEnterprise ? 'List' : 'Status'}</CopytLabel>
                              </TableCell>
                              <TableCell>
                                <CopytLabel>{isEnterprise ? 'Withdraw' : 'Payout Info'}</CopytLabel>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            style={{
                              backgroundColor: 'white',
                              borderBottom: '1px solid var(--gray)',
                            }}
                          >
                            {isEmpty(batch?.consigns) ? (
                              <TableRow>
                                <TableCell colSpan={7} align="center">
                                  <Stack alignItems="center" py={3}>
                                    <ListingEmptyState className="empty-state-svg" />
                                    <Typography textAlign="center">No consigns available</Typography>
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            ) : (
                              batch?.consigns?.map((_item, index) => {
                                const item = filterConsignProductListings(_item)
                                const buttonText = getListButtonText(item)
                                const isDisabledListButton = isListButtonDisabled(item) || isDisabled

                                return (
                                  <TableRow key={index}>
                                    <TableCell
                                      sx={{
                                        maxWidth: '200px',
                                        wordWrap: 'break-word',
                                        whiteSpace: 'normal',
                                      }}
                                    >
                                      <CopytLabel>{item?.product?.title}</CopytLabel>
                                    </TableCell>
                                    <TableCell>
                                      <CopytLabel>{item?.product?.size}</CopytLabel>
                                    </TableCell>
                                    <TableCell>
                                      <CopytLabel>{batch?.location || 'default'}</CopytLabel>
                                    </TableCell>
                                    <TableCell>
                                      <CopytLabel>
                                        {currency.format(_getValue(item?.product?.desiredReturn))}
                                      </CopytLabel>
                                    </TableCell>
                                    <TableCell>
                                      <CopytLabel>{currency.format(item?.payout)}</CopytLabel>
                                    </TableCell>
                                    <TableCell>
                                      {isEnterprise ? (
                                        <Tooltip
                                          title={
                                            !item?.product?.sku
                                              ? 'Items must be assigned SKU/Style IDs to be listed.'
                                              : ''
                                          }
                                        >
                                          <span>
                                            <CopytStyledButton
                                              variant="contained"
                                              color="primary"
                                              disabled={isDisabledListButton}
                                              onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                setModalType(ModalType.LIST_ALL_PLATFORM)
                                                setSelectionModel([item])
                                              }}
                                              sx={{width: '140px'}}
                                            >
                                              {isDisabled ? (
                                                <CircularProgress size={24} sx={{color: 'white'}} />
                                              ) : (
                                                buttonText
                                              )}
                                            </CopytStyledButton>
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        <Button
                                          endIcon={<ThumbUpAltIcon />}
                                          size="small"
                                          variant="outlined"
                                          style={{
                                            pointerEvents: 'none',
                                            cursor: 'default',
                                            minWidth: '70%',
                                            borderRadius: 20,
                                            border: `1px solid ${_getStatusColor(item?.status)}`,
                                            color: _getStatusColor(item?.status),
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                          }}
                                        >
                                          <TooltipSnippets title={_getStatus(item?.status)} />
                                        </Button>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {isEnterprise &&
                                      item?.status?.toLowerCase() !== ListingStatus.WITHDRAW_APPROVED ? (
                                        <CopytStyledButton
                                          variant="contained"
                                          color="error"
                                          disabled={
                                            isDisabled || item?.status === ListingStatus.PROCESSED
                                          }
                                          onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            setModalType(ModalType.WITHDRAW)
                                            const withdraw = {
                                              ...item,
                                              consign: {
                                                consignor: batch?.consignor,
                                                withdraw,
                                              },
                                            }
                                            setPayload({...payload, withdraw: [withdraw]})
                                          }}
                                          sx={{width: '140px'}}
                                        >
                                          {isDisabled ? (
                                            <CircularProgress size={24} sx={{color: 'white'}} />
                                          ) : (
                                            'Withdraw'
                                          )}
                                        </CopytStyledButton>
                                      ) : isEnterprise ? (
                                        <Typography lineHeight={1}>
                                          {_getStatus(item?.status)}
                                        </Typography>
                                      ) : (
                                        <Typography />
                                      )}
                                    </TableCell>
                                  </TableRow>
                                )
                              })
                            )}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  )
                })
              )}
            </Stack>
          </Box>
        ) : (
          <EmptyData title="No Consigned Items">
            Create your first batch of inventory on the
            <span
              onClick={() => history.push('/admin/inventory')}
              className="pink-text ml-1 cursor-pointer"
            >
              Inventory page
            </span>
            .
          </EmptyData>
        )}
        {![
          ModalType.WITHDRAW,
          ModalType.ECG_REFERRAL_CODE,
          ModalType.APPLY,
          ModalType.CHOOSE_CONSIGNEE,
          ModalType.SELECT_APPROVED_ITEMS,
          ModalType.CREATE_BOX,
          ModalType.ECG_ITEM_SUBMISSION,
          ModalType.LIST_ALL_PLATFORM,
          ModalType.CONSIGNOR_PROFILE,
          ModalType.BARCODE_TEMPLATE,
        ].includes(modalType) ? (
          <ShippingPrompt
            modalType={modalType}
            setModalType={setModalType}
            consignmentBatchItems={consignmentBatchItems}
            onCreate={async () => {
              await refetchConsignmentBatch()
            }}
          />
        ) : (
          <PlatformsModal
            {...{
              modalType,
              setModalType,
              storeName,
              payload,
              setPayload,
              setSelectionModel,
              handleConsignmentBatchPayment,
            }}
            // barcode templates
            {...{
              barcodeTemplates,
              printSize,
              setBarcodeTemplates,
              setPrintSize,
              onGenerateBarcodesClicked,
              showBarcodeTemplate,
            }}
            onShowBarcodeTemplate={setShowBarcodeTemplate}
            onSaveChanges={onSaveChanges}
            onHandlePrint={handlePrint}
            data={payload?.withdraw}
          />
        )}
      </DetailView.Panel>
    </SearchContext.Provider>
  )
}

export default Batches
