import {create} from 'zustand'

export const useDashboard = create((set) => ({
  filter: {
    product: {
      title: '',
      sku: '',
      location: '',
      isOwned: true,
      isConsigned: true,
      category: 'all',
    },
    sold: {
      startDate: null,
      endDate: new Date(),
    },
    setProduct: (product) =>
      set((state) => ({
        filter: {
          ...state.filter,
          product: {...state.filter.product, ...product},
        },
      })),
    setSold: (sold) =>
      set((state) => ({
        filter: {
          ...state.filter,
          sold: {...state.filter.sold, ...sold},
        },
      })),
  },
}))
