export const UnlistedTableFilterMenu = [
  {id: 'title', title: 'Name'},
  {id: 'sku', title: 'SKU/Style ID'},
  {id: 'size', title: 'Size'},
  {id: 'condition', title: 'Condition'},
  {id: 'price', title: 'Buy Price'},
  {id: 'desiredReturn', title: 'List Price'},
  {id: 'internalSku', title: 'Internal SKU'},
  {id: 'category', title: 'Category'},
  {id: 'location', title: 'Location'},
  {id: 'subLocation', title: 'Sub Location'},
  {id: 'acquiredDate', title: 'Acquired Date'},
  {id: 'owner', title: 'Owner'},
  {id: 'consignmentStatus', title: 'Consignment Status'},
]

export const ListedTableFilterMenu = [
  {id: 'title', title: 'Name'},
  {id: 'sku', title: 'SKU/Style ID'},
  {id: 'size', title: 'Size'},
  {id: 'condition', title: 'Condition'},
  {id: 'price', title: 'Buy Price'},
  {id: 'desiredReturn', title: 'List Price'},
  {id: 'internalSku', title: 'Internal SKU'},
  {id: 'category', title: 'Category'},
  {id: 'location', title: 'Location'},
  {id: 'subLocation', title: 'Sub Location'},
  {id: 'platform', title: 'Platform'},
  {id: 'owner', title: 'Owner'},
  {id: 'listingPlatformStatus', title: 'Status'},
  {id: 'consignmentStatus', title: 'Consignment Status'},
]

export const SoldTableFilterMenu = [
  {id: 'title', title: 'Name'},
  {id: 'sku', title: 'SKU/Style ID'},
  {id: 'size', title: 'Size'},
  {id: 'actualReturn', title: 'Actual Return'},
  {id: 'profit', title: 'Profit'},
  {id: 'soldPlatform', title: 'Sold Platform'},
  {id: 'soldDate', title: 'Sold Date'},
  {id: 'internalSku', title: 'Internal Sku'},
  {id: 'category', title: 'Category'},
  {id: 'location', title: 'Location'},
  {id: 'subLocation', title: 'Sub Location'},
  {id: 'consignmentStatus', title: 'Consignment Status'},
]

export const ProcessedTableFilterMenu = [
  {id: 'title', title: 'Name'},
  {id: 'sku', title: 'SKU/Style ID'},
  {id: 'size', title: 'Size'},
  {id: 'actualReturn', title: 'Actual Return'},
  {id: 'profit', title: 'Profit'},
  {id: 'soldPlatform', title: 'Sold Platform'},
  {id: 'soldDate', title: 'Sold Date'},
  {id: 'internalSku', title: 'Internal Sku'},
  {id: 'category', title: 'Category'},
  {id: 'location', title: 'Location'},
  {id: 'subLocation', title: 'Sub Location'},
  {id: 'consignmentStatus', title: 'Consignment Status'},
]

export const NetworkFilterMenu = [
  {id: 'title', title: 'Name', alternateTitle: null, isHide: false, width: '39%'},
  {id: 'sku', title: 'SKU/Style ID', alternateTitle: null, isHide: false, width: '15%'},
  {id: 'size', title: 'Size', alternateTitle: null, isHide: false, width: '9%'},
  {id: 'condition', title: 'Condition', alternateTitle: null, isHide: false, width: '9%'},
  {id: 'listPriceRange', title: 'List Price Range', alternateTitle: 'Price', isHide: false, width: '9%'},
  {id: 'email', title: 'Owner', alternateTitle: null, isHide: true, width: '9%'},
  {id: 'quantity', title: 'Quantity', alternateTitle: null, isHide: false, width: '6%'},
]

export const ShippingMenu = [
  {id: 'purchasedAt', title: 'Order', alternateTitle: null, isHide: false, flex: 0.5},
  {id: 'status', title: 'Status', alternateTitle: null, isHide: false, flex: 0.9},
  {id: 'recipient', title: 'Recipient', alternateTitle: null, isHide: false, flex: 0.9},
  {id: 'type', title: 'Type', alternateTitle: null, isHide: false, flex: 0.5},
  {id: 'package', title: 'Package', alternateTitle: null, isHide: false, flex: 1},
  {id: 'tracking', title: 'Tracking', alternateTitle: null, isHide: false, flex: 1.2},
  {id: 'price', title: 'Cost', alternateTitle: null, isHide: false, flex: 0.5},
  {id: 'items', title: 'Items', alternateTitle: null, isHide: false, flex: 2},
]

export const AuditTableFilterMenu = [
  {id: 'title', title: 'Name'},
  {id: 'status', title: 'Status'},
  {id: 'location', title: 'Location'},
]

const inventoryFilterMenu = {
  UnlistedTableFilterMenu,
  ListedTableFilterMenu,
  SoldTableFilterMenu,
  ProcessedTableFilterMenu,
  NetworkFilterMenu,
  AuditTableFilterMenu,
}

export default inventoryFilterMenu
