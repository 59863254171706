import {useState, useEffect} from 'react'
import {useMutation, useQuery} from 'react-query'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import {captureException} from '@sentry/react'
import {get, isEmpty, map} from 'lodash'

import {useToastMessage} from 'components'
import {paths} from 'constant/api'
import {createRequestOptions} from 'util/network'
import {ar} from 'variables/empty'
import {apiConfig} from 'config'

const getMetrics = async (getAccessTokenSilently, filter, queries) => {
  delete queries.page
  delete queries.skip
  delete queries.take

  try {
    const token = await getAccessTokenSilently()

    if (!isEmpty(filter) || !isEmpty(queries)) {
      const queryString = map(
        {...queries, ...filter},
        (value, key) => `${key}=${encodeURIComponent(value).replace(/%2F/g, '/')}`,
      ).join('&')

      const response = await axios.get(
        `${paths.shipment}/metrics?${queryString}`,
        createRequestOptions(token),
      )

      return response?.data?.data
    }

    const response = await axios.get(`${paths.shipment}/metrics`, createRequestOptions(token))

    return response?.data?.data
  } catch (error) {
    captureException(error)
    console.error('Error retrieving invoices', error)
    throw error
  }
}

const getShipments = async (getAccessTokenSilently, filter = {}, queries = {}) => {
  delete queries.page
  delete queries.skip
  delete queries.take

  try {
    const token = await getAccessTokenSilently()
    if (!isEmpty(filter) || !isEmpty(queries)) {
      const queryString = map(
        {...queries, ...filter},
        (value, key) => `${key}=${encodeURIComponent(value).replace(/%2F/g, '/')}`,
      ).join('&')

      const response = await axios.get(
        `${paths.shipment}/all?${queryString}`,
        createRequestOptions(token),
      )

      return response?.data
    }

    return []
  } catch (error) {
    captureException(error)
    console.error('Error retrieving invoices', error)
    throw error
  }
}

const getShipment = async (getAccessTokenSilently, shipmentId) => {
  try {
    if (shipmentId) {
      const token = await getAccessTokenSilently()
      const response = await axios.get(`${paths.shipment}/${shipmentId}`, createRequestOptions(token))

      return response?.data?.data
    }
  } catch (error) {
    captureException(error)
    console.error('Error retrieving invoices', error)
    throw error
  }
}

const getShipmentConstant = async (getAccessTokenSilently) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(`${paths.shipment}/constant`, createRequestOptions(token))

    return response?.data?.data
  } catch (error) {
    captureException(error)
    console.error('Error retrieving invoices', error)
    throw error
  }
}

const getPaymentMethod = async (getAccessTokenSilently) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(`${paths.payment}/method`, createRequestOptions(token))

    const payment = get(response?.data?.data, 'method', null)
    const billingDetails = get(payment, 'billing_details', null)

    return {
      address: billingDetails?.address?.line1,
      city: billingDetails?.address?.city,
      country: billingDetails?.address?.country,
      zip: billingDetails?.address?.postal_code,
      state: billingDetails?.address?.state,
      name: billingDetails?.name,
      phone: billingDetails?.phone,
      email: billingDetails?.email,
    }
  } catch (error) {
    captureException(error)
    console.error('Error retrieving addresses', error)
    throw error
  }
}

const getAddress = async (getAccessTokenSilently) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(`${paths.shipment}/address/all`, createRequestOptions(token))

    return response?.data?.data
  } catch (error) {
    captureException(error)
    console.error('Error retrieving addresses', error)
    throw error
  }
}

const getParcelPreset = async (getAccessTokenSilently) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(`${paths.shipmentParcelPreset}/all`, createRequestOptions(token))

    return response?.data?.data
  } catch (error) {
    captureException(error)
    console.error('Error retrieving addresses', error)
    throw error
  }
}

export const useShipments = (filter = {}, queries = {}, searchText = '', options = {}) => {
  const {getAccessTokenSilently} = useAuth0()
  const {showToast} = useToastMessage()
  const [fetchShipmentsEnabled, setFetchShipmentsEnabled] = useState(true)
  const [isSuccessGenerateShipment, setIsSuccessGenerateShipment] = useState(false)
  const [isSuccessPurchaseShipment, setIsSuccessPurchaseShipment] = useState(false)
  const [isSuccessRefundShipment, setIsSuccessRefundShipment] = useState(false)
  const [isSuccessShipmentById, setIsSuccessShipmentById] = useState(false)
  const [isSuccessPaymentMethod, setIsSuccessPaymentMethod] = useState(false)
  const [isErrorRefundShipment, setIsErrorRefundShipment] = useState(false)
  const [isErrorPurchaseShipment, setIsErrorPurchaseShipment] = useState(false)
  const [isLoadingGetShipmentById, setIsLoadingGetShipmentById] = useState(false)
  const [shipment, setShipment] = useState(null)
  const [labels, setLabels] = useState(null)
  const [shippingDetails, setShippingDetails] = useState({sameAsBilling: false})
  const [generateShipmentError, setGenerateShipmentError] = useState({})
  const [createAnotherShipmentId, setCreateAnotherShipmentId] = useState(undefined)

  useEffect(() => {
    ;(async () => {
      try {
        const token = await getAccessTokenSilently()
        const response = await axios.get(
          `${apiConfig.api_url}/payment/method`,
          createRequestOptions(token),
        )

        const {data} = response.data
        if (response.status == 200 && response?.data) {
          const customer = get(data, 'customer', null)
          const shipping = get(customer, 'shipping', null)

          const flatResponse = {
            addressShipping: shipping
              ? shipping?.address?.line1 +
                (shipping?.address?.line2 ? ' ' + shipping?.address?.line2 : '')
              : '',
            cityShipping: shipping?.address?.city || '',
            stateShipping: shipping?.address?.state || '',
            zipShipping: shipping?.address?.postal_code || '',

            sameAsBilling: data.sameAsBilling,
          }

          setShippingDetails(flatResponse)
        }
      } catch (e) {
        console.error('exception', e)
      }
    })()
  }, [getAccessTokenSilently])

  const {
    isLoading,
    isError,
    isFetching,
    isFetched,
    refetch,
    data: shipments = ar,
    error,
  } = useQuery(
    ['shipments', filter, queries],
    () => getShipments(getAccessTokenSilently, filter, queries),
    {
      ...options,
      keepPreviousData: true,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    },
  )

  const shipmentConstant = useQuery(
    ['shipmentConstant'],
    () => getShipmentConstant(getAccessTokenSilently),
    {
      keepPreviousData: true,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    },
  )

  const shipmentMetrics = useQuery({
    queryKey: ['shipmentMetrics', filter, queries],
    queryFn: () => getMetrics(getAccessTokenSilently, filter, queries),
    keepPreviousData: true,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  })

  const createShipment = useMutation(
    async (payload) => {
      const token = await getAccessTokenSilently()

      return await axios.post(`${paths.shipment}/create`, {...payload}, createRequestOptions(token))
    },
    {
      onSuccess: async (res) => {
        const retrievedShipment = await getShipment(getAccessTokenSilently, res?.data?.data?.id)
        setShipment(retrievedShipment)
        setFetchShipmentsEnabled(true)
        showToast({variant: 'success', title: 'Created Shipment', body: ''})
        refetch()
      },
      onError: async (data, variables, context) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
        setFetchShipmentsEnabled(true)
        refetch()
      },
    },
  )

  const createAnotherShipment = useMutation(
    async ({id, count, ...payload}) => {
      const token = await getAccessTokenSilently()

      return await axios.post(
        `${paths.shipment}/${id}/duplicate?count=${parseInt(count || 1)}`,
        {...payload},
        createRequestOptions(token),
      )
    },
    {
      onSuccess: async (res) => {
        const retrievedShipment = await getShipment(getAccessTokenSilently, res?.data?.data?.id)
        setShipment(retrievedShipment)
        setFetchShipmentsEnabled(true)
        showToast({variant: 'success', title: 'Created Shipment', body: ''})
        setCreateAnotherShipmentId(undefined)
        refetch()
      },
      onError: async (data, variables, context) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
        setFetchShipmentsEnabled(true)
        setCreateAnotherShipmentId(undefined)
        refetch()
      },
    },
  )

  const applyShipmentDiscount = useMutation(
    async ({shipmentId, ...payload}) => {
      const token = await getAccessTokenSilently()

      return await axios.post(
        `${paths.shipment}/${shipmentId}/discount/apply`,
        {...payload},
        createRequestOptions(token),
      )
    },
    {
      onSuccess: async (res) => {
        const retrievedShipment = await getShipment(getAccessTokenSilently, res?.data?.data?.id)
        setShipment(retrievedShipment)
        setFetchShipmentsEnabled(true)
        showToast({variant: 'success', title: 'Discount applied', body: ''})
        refetch()
      },
      onError: async (data, variables, context) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
        setFetchShipmentsEnabled(true)
        refetch()
      },
    },
  )

  const updateShipment = useMutation(
    async ({id, ...payload}) => {
      const token = await getAccessTokenSilently()
      return await axios.patch(`${paths.shipment}/${id}`, {...payload}, createRequestOptions(token))
    },
    {
      onSuccess: async (res) => {
        const retrievedShipment = await getShipment(getAccessTokenSilently, res?.data?.data?.id)
        setShipment(retrievedShipment)
        setFetchShipmentsEnabled(true)
        refetch()
      },
      onMutate: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Loading', body: ''})
      },
      onError: async (data, variables, context) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
        setFetchShipmentsEnabled(true)
        refetch()
      },
    },
  )

  const generateShipment = useMutation(
    async ({payload, senderId, recipientId}) => {
      const token = await getAccessTokenSilently()
      let url = `${paths.shipment}/${shipment?.id}/generate`
      const params = new URLSearchParams()
      if (senderId) params.append('senderId', senderId)
      if (recipientId) params.append('recipientId', recipientId)
      if (params.toString()) url += `?${params.toString()}`

      return await axios.post(url, {...payload}, createRequestOptions(token))
    },
    {
      onSuccess: async (res, variables, context) => {
        const retrievedShipment = await getShipment(getAccessTokenSilently, res?.data?.data?.id)
        setShipment(retrievedShipment)
        setGenerateShipmentError({})
        setIsSuccessGenerateShipment(true)
        setFetchShipmentsEnabled(true)
        refetch()
      },
      onMutate: async () => {
        setIsSuccessGenerateShipment(false)
      },
      onError: ({response, message}) => {
        if (response?.status == 422) {
          setGenerateShipmentError(response?.data?.error)
        }

        if (response?.status == 409) {
          setGenerateShipmentError(response?.data?.error)
        }

        if (response?.status === 500) {
          setGenerateShipmentError({})
        }

        setIsSuccessGenerateShipment(false)
        showToast({variant: 'danger', title: 'Error', body: message})
        setFetchShipmentsEnabled(true)
        refetch()
      },
    },
  )

  const purchaseShipment = useMutation(
    async ({isIncludeDuplicates, ...payload}) => {
      const token = await getAccessTokenSilently()
      return await axios.post(
        `${paths.shipment}/${shipment?.id}/purchase${isIncludeDuplicates ? '?include=duplicates' : ''}`,
        {...payload},
        createRequestOptions(token),
      )
    },
    {
      onSuccess: async (res, variables, context) => {
        if (res?.data) {
          const retrievedShipment = await getShipment(getAccessTokenSilently, res?.data?.data?.id)
          setShipment(retrievedShipment)
          setIsErrorPurchaseShipment(false)
          setIsSuccessPurchaseShipment(true)
          setFetchShipmentsEnabled(true)
          refetch()
        }
      },
      onMutate: async (data) => {
        setIsErrorPurchaseShipment(false)
        setIsSuccessPurchaseShipment(false)
      },
      onError: (error) => {
        const {response} = error
        setIsSuccessPurchaseShipment(false)
        setIsErrorPurchaseShipment(true)

        let isShippo = response?.data?.error?.shippo

        const message = isShippo
          ? response?.data?.error?.shippo?.transaction?.messages[0]?.text
          : response?.data?.error

        showToast({variant: 'danger', title: 'Error', body: message})
        setFetchShipmentsEnabled(true)
        refetch()
      },
    },
  )

  const deleteShipment = useMutation(
    async (id) => {
      const token = await getAccessTokenSilently()

      return await axios.delete(`${paths.shipment}/${id}`, createRequestOptions(token))
    },
    {
      onSuccess: async (res, variables, context) => {
        showToast({variant: 'success', title: 'Deleted', body: ''})
        refetch()
      },
      onMutate: async () => {},
      onError: (e) => {
        showToast({variant: 'danger', title: 'Error', body: e.message})
        refetch()
      },
    },
  )

  const refundShipment = useMutation(
    async (payload) => {
      const token = await getAccessTokenSilently()

      return await axios.post(
        `${paths.shipment}/refund/create`,
        {...payload},
        createRequestOptions(token),
      )
    },
    {
      onSuccess: async (res, variables, context) => {
        showToast({variant: 'success', title: 'Refund Request Submitted!', body: ''})
        setIsSuccessRefundShipment(true)
        setFetchShipmentsEnabled(true)
        setIsErrorRefundShipment(false)
        refetch()
      },
      onMutate: async () => {
        setIsSuccessRefundShipment(false)
        setIsErrorRefundShipment(false)
      },
      onError: (e) => {
        setIsSuccessRefundShipment(false)
        setIsErrorRefundShipment(true)
        showToast({variant: 'danger', title: 'Error', body: e.message})
        setFetchShipmentsEnabled(true)
        refetch()
      },
    },
  )

  const getShipmentById = useMutation(async (shipmentId) => {
    setIsLoadingGetShipmentById(true)
    setIsSuccessShipmentById(false)
    try {
      const retrievedShipment = await getShipment(getAccessTokenSilently, shipmentId)
      setShipment(retrievedShipment)
    } finally {
      setIsLoadingGetShipmentById(false)
      setIsSuccessShipmentById(true)
    }
  })

  const createStripePayment = useMutation(
    async ({cardElement, billingDetails, email, stripe}) => {
      const paymentMethod = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          address: {
            line1: billingDetails.address,
            city: billingDetails.city,
            state: billingDetails.state,
            postal_code: billingDetails.zip,
          },
          name: billingDetails.name,
        },
      })

      if (paymentMethod.error) {
        throw new Error(paymentMethod.error.message)
      } else {
        const token = await getAccessTokenSilently()
        await axios.post(
          `${paths?.payment}/subscription`,
          {
            paymentMethodId: paymentMethod.paymentMethod.id,
            name: billingDetails.name,
            email: email,
          },
          createRequestOptions(token),
        )
      }
    },
    {
      onSuccess: () => {
        showToast({variant: 'success', title: 'Payment Method Successfully Added', body: ''})
        setIsSuccessPaymentMethod(true)
      },
      onError: (e) => {
        showToast({variant: 'danger', title: 'Payment Error', body: e.message})
        setIsSuccessPaymentMethod(false)
      },
    },
  )

  const downloadShipmentLabels = useMutation(
    async (payload) => {
      const token = await getAccessTokenSilently()

      return await axios.post(
        `${paths.shipment}/download/labels`,
        {...payload},
        createRequestOptions(token),
      )
    },
    {
      onSuccess: async (res) => {
        setLabels(res?.data?.data)
      },
      onError: (e) => {
        showToast({variant: 'danger', title: 'Error', body: e?.message})
      },
    },
  )

  useEffect(() => {
    if (fetchShipmentsEnabled) {
      refetch()
      setFetchShipmentsEnabled(false)
    }
  }, [fetchShipmentsEnabled, refetch])

  useEffect(() => {
    if (queries || filter) {
      setFetchShipmentsEnabled(true)
    }
  }, [queries, filter])

  return {
    isLoading,
    isFetching,
    isError,
    isShipmentsFetched: isFetched,
    isSuccessGenerateShipment,
    isSuccessRefundShipment,
    isSuccessPurchaseShipment,
    isSuccessShipmentById,
    isSuccessPaymentMethod,
    isErrorRefundShipment,
    isErrorPurchaseShipment,
    isLoadingGetShipmentById,
    refetchShipments: () => setFetchShipmentsEnabled(true),
    error,
    shipments: shipments?.data,
    shipmentsCount: shipments?.count,
    shipmentMetrics: shipmentMetrics.data,
    isShipmentMetricsLoading: shipmentMetrics.isLoading,
    shipment,
    setShipment,
    labels,
    generateShipmentError,
    shipmentConstant: shipmentConstant.data,
    createShipment: createShipment.mutateAsync,
    isCreatingShipment: createShipment.isLoading,
    createAnotherShipment: createAnotherShipment.mutateAsync,
    isCreatingAnotherShipment: createAnotherShipment.isLoading,
    createAnotherShipmentId,
    setCreateAnotherShipmentId,
    updateShipment: updateShipment.mutateAsync,
    isUpdatingShipment: updateShipment.isLoading,
    downloadShipmentLabels: downloadShipmentLabels.mutateAsync,
    generateShipment: generateShipment.mutateAsync,
    isGeneratingShipment: generateShipment.isLoading,
    deleteShipment: deleteShipment.mutateAsync,
    isDeletingShipment: deleteShipment.isLoading,
    purchaseShipment: purchaseShipment.mutateAsync,
    isPurchasingShipment: purchaseShipment.isLoading,
    refundShipment: refundShipment.mutateAsync,
    isRefundingShipment: refundShipment.isLoading,
    getShipmentById: getShipmentById.mutateAsync,
    getPaymentMethod: getPaymentMethod.mutate,
    createStripePayment: createStripePayment.mutateAsync,
    applyShipmentDiscount: applyShipmentDiscount.mutateAsync,
    isApplyingShipmentDiscount: applyShipmentDiscount.isLoading,
    shippingDetails,
  }
}

export const useAddress = (options = {}) => {
  const {getAccessTokenSilently} = useAuth0()
  const {showToast} = useToastMessage()
  const [fetchAddressEnabled, setFetchAddressEnabled] = useState(true)

  const {
    isLoading,
    isError,
    isFetched,
    refetch,
    data: address = ar,
    error,
  } = useQuery('address', () => getAddress(getAccessTokenSilently), {
    ...options,
    keepPreviousData: true,
    enabled: fetchAddressEnabled,
  })

  useEffect(() => {
    if (fetchAddressEnabled) {
      refetch()
      setFetchAddressEnabled(false)
    }
  }, [fetchAddressEnabled, refetch])

  return {
    isLoading,
    isError,
    isAddressFetched: isFetched,
    refetchAddress: () => setFetchAddressEnabled(true),
    error,
    address: address,
  }
}

export const useShipmentParcelPreset = (options = {}) => {
  const {getAccessTokenSilently} = useAuth0()
  const {showToast} = useToastMessage()
  const [fetchParcelPresetEnabled, setFetchParcelPresetEnabled] = useState(true)

  const {
    isLoading,
    isError,
    isFetched,
    refetch,
    isFetching,
    data: parcelPreset = ar,
    error,
  } = useQuery('parcelPreset', () => getParcelPreset(getAccessTokenSilently), {
    ...options,
    keepPreviousData: true,
    enabled: fetchParcelPresetEnabled,
  })

  const createParcelPreset = useMutation(
    async (value) => {
      const token = await getAccessTokenSilently()

      return await axios.post(
        `${paths.shipmentParcelPreset}/create`,
        {...value},
        createRequestOptions(token),
      )
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({
          variant: 'success',
          title: 'Success',
          body: `Parcel preset has been added.`,
        })
        setFetchParcelPresetEnabled(true)
        refetch()
      },
      onMutate: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Loading', body: ''})
      },
      onError: async (data, variables, context) => {},
    },
  )

  const updateParcelPreset = useMutation(
    async ({id, ...payload}) => {
      const token = await getAccessTokenSilently()

      return await axios.patch(
        `${paths.shipmentParcelPreset}/${id}`,
        {...payload},
        createRequestOptions(token),
      )
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({
          variant: 'success',
          title: 'Success',
          body: `Parcel preset has been updated.`,
        })
        refetch()
      },
      onMutate: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Loading', body: ''})
      },
      onError: async (data, variables, context) => {},
    },
  )

  const deleteParcelPreset = useMutation(
    async (id) => {
      const token = await getAccessTokenSilently()

      return await axios.delete(`${paths.shipmentParcelPreset}/${id}`, createRequestOptions(token))
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({
          variant: 'success',
          title: 'Success',
          body: `Parcel preset has been deleted.`,
        })
        refetch()
      },
      onMutate: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Loading', body: ''})
      },
      onError: async (data, variables, context) => {},
    },
  )

  useEffect(() => {
    if (fetchParcelPresetEnabled) {
      refetch()
      setFetchParcelPresetEnabled(false)
    }
  }, [fetchParcelPresetEnabled, refetch])

  return {
    isLoading,
    isFetching,
    isError,
    isParcelPresetFetched: isFetched,
    refetchParcelPreset: () => setFetchParcelPresetEnabled(true),
    error,
    parcelPreset: parcelPreset,
    deleteParcelPreset: deleteParcelPreset.mutateAsync,
    deletingParcelPreset: deleteParcelPreset.isLoading,
    updateParcelPreset: updateParcelPreset.mutateAsync,
    updatingParcelPreset: updateParcelPreset.isLoading,
    createParcelPreset: createParcelPreset.mutateAsync,
    creatingParcelPreset: createParcelPreset.isLoading,
  }
}

export const useShipment = (shipmentId) => {
  const {getAccessTokenSilently} = useAuth0()
  const {showToast} = useToastMessage()
  const [fetchShipmentEnabled, setFetchShipmentEnabled] = useState(true)

  const {
    isLoading,
    isError,
    isFetched,
    refetch,
    data: shipment = {},
    error,
  } = useQuery('shipment', () => getShipment(getAccessTokenSilently, shipmentId), {
    ...options,
    keepPreviousData: true,
    enabled: fetchShipmentEnabled,
  })

  useEffect(() => {
    if (fetchShipmentEnabled) {
      refetch()
      setFetchShipmentEnabled(false)
    }
  }, [fetchShipmentEnabled, refetch])

  return {
    isLoading,
    isError,
    isShipmentFetched: isFetched,
    refetchShipmentFetched: () => setFetchShipmentEnabled(true),
    error,
    shipment: shipment,
  }
}

export const usePaymentMethod = () => {
  const {getAccessTokenSilently} = useAuth0()
  const [fetchPaymentMethodEnabled, setFetchPaymentMethodEnabled] = useState(true)

  const {
    isLoading,
    isError,
    isFetched,
    refetch,
    data: paymentMethod = {},
    error,
  } = useQuery('payment-method', () => getPaymentMethod(getAccessTokenSilently), {
    keepPreviousData: true,
    enabled: fetchPaymentMethodEnabled,
  })

  useEffect(() => {
    if (fetchPaymentMethodEnabled) {
      refetch()
      setFetchPaymentMethodEnabled(false)
    }
  }, [fetchPaymentMethodEnabled, refetch])

  return {
    isLoading,
    isError,
    isPaymentMethodFetched: isFetched,
    refetchPaymentMethodtFetched: () => setFetchPaymentMethodEnabled(true),
    error,
    paymentMethod: paymentMethod,
  }
}

export default useShipments
