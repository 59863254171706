import React, {useState, useEffect} from 'react'
import {range, sortBy} from 'lodash'
import {Box, Button, Checkbox, FormControlLabel, Grid, Stack, Typography} from '@mui/material'
import Select from 'react-select'
import Barcode from 'react-barcode'
import styled from 'styled-components'
import {makeStyles} from '@material-ui/core/styles'

import {useGlobalStore} from 'provider/global_store/hook'
import {CopytStyledButton, CopytTooltip} from 'views_v2/lib/snippets'

export const GridItem = styled(Grid)(() => ({
  border: '1px dashed',
  backgroundColor: '#0000001f',
  minWidth: '90px',
  width: 'auto',
  paddingRight: 1,
  paddingLeft: 1,
}))

export const BoxItem = styled(Box)(() => ({
  border: '1px dashed',
  backgroundColor: '#0000001f',
  minWidth: '90px',
  width: 'auto',
  paddingRight: 1,
  paddingLeft: 1,
}))

export const TextTemplate = styled(Typography)(() => ({
  fontSize: '0.85rem',
  textAlign: 'center',
}))

const useStyles = makeStyles(() => ({
  boxContainer: {
    boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.24)',
    color: 'var(--primary) !important',
    width: '105px',
    height: '40px',
    lineHeight: '1rem',
    fontSize: '0.8rem',
    fontWeight: 600,
    borderRadius: '10px !important',
  },
}))

const sizeOptions = [
  {value: '3.5x1.2', label: '3.5x1.2'},
  {value: '2.2x1.1', label: '2.2x1.1'},
]

const initial = [
  {id: 'boxCondition', label: 'Box Cond.', isHidden: false},
  {id: 'subLocation', label: 'Sub Location', isHidden: false},
  {id: 'size', label: 'Size', isHidden: false},
  {id: 'condition', label: 'Item Cond.', isHidden: false},
  {id: 'desiredReturn', label: 'List Price', isHidden: false},
]

const ConsignorCode = () => {
  return (
    <BoxItem sx={{width: '110px', position: 'absolute', bottom: '20%', right: '6%'}}>
      <TextTemplate>Consignor Code</TextTemplate>
    </BoxItem>
  )
}

const updateHiddenStatus = (template, array) => {
  return array.map((item) => {
    if (['desiredReturn', 'price'].some((_) => _ === item?.id)) {
      return {...item, isHidden: !template.listPrice}
    } else if (item.id === 'boxCondition') {
      return {...item, isHidden: !template.boxCondition}
    }
    return item
  })
}

const MdlBarcodeTemplate = (p) => {
  const {showBarcodeTemplate, onShowBarcodeTemplate} = p
  const [template, setTemplate] = useState(0)
  const [topStrings, setTopStrings] = useState([])
  const [bottomStrings, setBottomStrings] = useState([])
  const [templates, setTemplates] = useState(initial)
  const {isEnterprise} = useGlobalStore()

  useEffect(() => {
    onShowBarcodeTemplate({
      boxCondition: showBarcodeTemplate?.boxCondition ?? true,
      listPrice: showBarcodeTemplate?.listPrice ?? true,
      consignorCode: showBarcodeTemplate?.consignorCode ?? true,
    })
  }, [])

  useEffect(() => {
    randomizeStrings()
  }, [template, showBarcodeTemplate])

  const handleSizeChange = (selectedOption) => {
    const val = selectedOption.value
    const value = val.split('x').map(parseFloat)
    p.setPrintSize({width: value[0], height: value[1]})
  }

  const price = !isEnterprise ? 'price' : 'desiredReturn'

  const randomizeStrings = () => {
    let updatedArray = [...updateHiddenStatus(showBarcodeTemplate, templates)]

    if (!isEnterprise) {
      const item = updatedArray.find((s) => s.id === 'desiredReturn')
      if (item) {
        Object.assign(item, {id: 'price', label: 'Price'})
      }
    }

    let order = ['size', 'condition', price, 'boxCondition', 'subLocation']
    switch (template) {
      case 1:
        order = ['condition', 'boxCondition', 'subLocation', 'size', price]
        break
      case 2:
        order = ['size', price, 'condition', 'boxCondition', 'subLocation']
        break
      case 3:
        order = [price, 'size', 'condition', 'boxCondition', 'subLocation']
        break
      case 4:
        order = ['condition', 'size', 'boxCondition', 'subLocation', price]
        break
      case 5:
        order = ['boxCondition', price, 'condition', 'size', 'subLocation']
        break
      case 6:
        order = [price, 'condition', 'size', 'boxCondition', 'subLocation']
        break
      case 7:
        order = ['size', 'condition', 'boxCondition', price]
        break
      case 8:
      case 9:
        order = ['location']
        break
      case 10:
        order = ['size', 'condition', 'boxCondition', 'subLocation', price]
        break
      default:
        break
    }

    updatedArray = sortBy(updatedArray, (item) => order.indexOf(item.id))

    if (template === 4) {
      setTopStrings([{id: 'size', label: 'Size', isHidden: false}])
      setBottomStrings(updatedArray.slice(4))
    } else if (template === 5) {
      setTopStrings([
        {
          id: price,
          label: isEnterprise ? 'List Price' : 'Price',
          isHidden: !showBarcodeTemplate?.listPrice,
        },
      ])
      setBottomStrings(updatedArray.slice(4))
    } else if (template === 7) {
      setTopStrings([
        {id: 'size', label: 'Size', isHidden: false},
        {id: 'condition', label: 'Item Cond.', isHidden: false},
        {id: 'boxCondition', label: 'Box Cond.', isHidden: !showBarcodeTemplate?.boxCondition},
      ])
      setBottomStrings([
        {
          id: price,
          label: isEnterprise ? 'List Price' : 'Price',
          isHidden: !showBarcodeTemplate?.listPrice,
        },
      ])
    } else if (template === 8 || template === 9) {
      setTopStrings(order)
      setBottomStrings([])
    } else if (template === 10) {
      setTopStrings(updatedArray.slice(0, 3))
      setBottomStrings([
        {
          id: price,
          label: isEnterprise ? 'List Price' : 'Price',
          isHidden: !showBarcodeTemplate?.listPrice,
        },
      ])
    } else {
      setTopStrings(updatedArray.slice(0, 3))
      setBottomStrings(updatedArray.slice(3))
    }
  }

  const classes = useStyles()
  const isStoreLocation = [8, 9].includes(template)

  const bodyContent = () => {
    if (isStoreLocation) {
      return (
        <Grid item>
          <Barcode value="Internal Sku" height={28} width={1.7} fontSize={12} />
          {template === 9 && (
            <BoxItem px={1}>
              <TextTemplate>Store Location</TextTemplate>
            </BoxItem>
          )}
          {showBarcodeTemplate?.consignorCode && <ConsignorCode />}
        </Grid>
      )
    } else if (template === 1) {
      return (
        <Grid container gap={1} justifyContent="space-between" mt={2}>
          {topStrings.map((str, index) => {
            if (index < topStrings.length - 1) {
              return (
                <GridItem item key={index} visibility={str?.isHidden ? 'hidden' : ''}>
                  <TextTemplate>{str.label}</TextTemplate>
                </GridItem>
              )
            }
          })}
          <Grid item>
            <Barcode value="Internal Sku" height={28} width={1.7} fontSize={12} />
          </Grid>
          {showBarcodeTemplate?.consignorCode && <ConsignorCode />}
        </Grid>
      )
    } else if (template === 4) {
      return (
        <Grid container gap={1} justifyContent="space-between" mt={1}>
          <Grid item>
            <GridItem item px={1}>
              <TextTemplate>Size</TextTemplate>
            </GridItem>
            <Barcode value="Internal Sku" height={30} width={1.7} fontSize={12} />
          </Grid>
          {showBarcodeTemplate?.consignorCode && <ConsignorCode />}
        </Grid>
      )
    } else if (template === 5) {
      return (
        <Grid container gap={1} justifyContent="space-between" mt={1}>
          <Grid item>
            <GridItem item px={1} visibility={!showBarcodeTemplate?.listPrice ? 'hidden' : ''}>
              <TextTemplate>{isEnterprise ? 'List Price' : 'Price'}</TextTemplate>
            </GridItem>
            <Barcode value="Internal Sku" height={30} width={1.7} fontSize={12} />
          </Grid>
          {showBarcodeTemplate?.consignorCode && <ConsignorCode />}
        </Grid>
      )
    } else if (template === 7) {
      return (
        <Grid container gap={1} justifyContent="space-between" mt={2}>
          {topStrings.map((str, index) => (
            <GridItem item key={index} px={1} visibility={str?.isHidden ? 'hidden' : ''}>
              <TextTemplate>{str.label}</TextTemplate>
            </GridItem>
          ))}
          <Grid item>
            <Barcode value="Internal Sku" height={28} width={1.7} fontSize={12} />
          </Grid>
          {showBarcodeTemplate?.consignorCode && <ConsignorCode />}
        </Grid>
      )
    } else if (template === 10) {
      return (
        <Stack direction="row">
          <Stack gap={1}>
            {topStrings.map((str, index) => {
              return (
                <GridItem item key={index} visibility={str?.isHidden ? 'hidden' : ''}>
                  <TextTemplate>{str.label}</TextTemplate>
                </GridItem>
              )
            })}
          </Stack>
          <Barcode value="Internal Sku" height={30} width={1.3} fontSize={12} />
          {showBarcodeTemplate?.consignorCode && <ConsignorCode />}
        </Stack>
      )
    }
    return (
      <Grid item>
        <Grid container gap={1} justifyContent="space-between">
          {topStrings.map((str, index) => (
            <GridItem item key={index} px={1} visibility={str?.isHidden ? 'hidden' : ''}>
              <TextTemplate>{str.label}</TextTemplate>
            </GridItem>
          ))}
        </Grid>
        <Grid container gap={1} justifyContent="space-between" mt={2}>
          {bottomStrings.map((str, index) => {
            return (
              <GridItem item key={index} visibility={str?.isHidden ? 'hidden' : ''}>
                <TextTemplate>{str.label}</TextTemplate>
              </GridItem>
            )
          })}
          <Grid item>
            <Barcode value="Internal Sku" height={28} width={1.7} fontSize={12} />
          </Grid>
          {showBarcodeTemplate?.consignorCode && <ConsignorCode />}
        </Grid>
      </Grid>
    )
  }

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Choose Template
          </Typography>
        </Box>
      </Box>
      <Box className="modal-body" padding={0} my={2}>
        <Stack spacing={2}>
          <Stack
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="flex-start"
            columnGap={2}
            alignItems="flex-end"
            spacing={2}
            px={2}
          >
            {range(11).map((index) => (
              <Button
                key={`template[${index}]`}
                className={classes.boxContainer}
                sx={{
                  backgroundColor:
                    index === template ? 'var(--lighter) !important' : '#ff8d723b !important',
                }}
                onClick={() => {
                  onShowBarcodeTemplate((_prev) => ({
                    ..._prev,
                    consignorCode: !(index === 7 && !!showBarcodeTemplate?.consignorCode),
                  }))

                  randomizeStrings()
                  setTemplate(index)
                }}
              >
                {index === 9 ? 'Store Location' : `Template ${index >= 10 ? index : index + 1}`}
              </Button>
            ))}
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" px={2}>
            {!isStoreLocation ? (
              <FormControlLabel
                sx={{marginLeft: 0, marginRight: 0}}
                control={
                  <Checkbox
                    color="secondary"
                    checked={!!showBarcodeTemplate?.listPrice}
                    onChange={(e) => {
                      onShowBarcodeTemplate((_prev) => ({
                        ..._prev,
                        listPrice: e.target.checked,
                      }))
                    }}
                  />
                }
                label="List Price"
                componentsProps={{
                  typography: {
                    sx: {
                      lineHeight: '15px',
                    },
                  },
                }}
              />
            ) : (
              <Box />
            )}
            {[0, 1, 2, 3, 6, 7, 10].some((_) => _ === template) ? (
              <FormControlLabel
                sx={{marginLeft: 0, marginRight: 0}}
                control={
                  <Checkbox
                    color="secondary"
                    checked={!!showBarcodeTemplate?.boxCondition}
                    onChange={(e) => {
                      onShowBarcodeTemplate((_prev) => ({
                        ..._prev,
                        boxCondition: e.target.checked,
                      }))
                    }}
                  />
                }
                componentsProps={{
                  typography: {
                    sx: {
                      lineHeight: '15px',
                    },
                  },
                }}
                label="Box Condition"
              />
            ) : (
              <Box />
            )}
            <CopytTooltip title="For consigned items only">
              <FormControlLabel
                sx={{marginLeft: 0, marginRight: 0}}
                control={
                  <Checkbox
                    color="secondary"
                    checked={!!showBarcodeTemplate?.consignorCode}
                    onChange={(e) => {
                      onShowBarcodeTemplate((_prev) => ({
                        ..._prev,
                        consignorCode: e.target.checked,
                      }))
                    }}
                  />
                }
                label="Consignor Code"
              />
            </CopytTooltip>
          </Stack>

          {template === 10 ? (
            <>
              <Box px={2}>
                <Box width="100%" minHeight="150px" bgcolor="var(--light)" borderRadius={2} p={1}>
                  <Stack
                    flex={1}
                    minHeight="130px"
                    height="170px"
                    maxHeight="170px"
                    bgcolor="white"
                    borderRadius={2}
                    gap={2}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      px={1}
                      pt={1}
                      alignItems="center"
                    >
                      <Box
                        className="company-logo"
                        py={2}
                        px={4}
                        backgroundColor="gray"
                        mr={1}
                        display="flex"
                        alignItems="center"
                      >
                        <Box color="white">Logo</Box>
                      </Box>
                      <Box width="50%">
                        <Typography fontWeight={600} fontSize={16}>
                          Item Name
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack direction="row" px={1} gap={2} justifyContent="space-evenly">
                      {bottomStrings.map((str, index) => (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          key={index}
                          border="1px dashed"
                          backgroundColor="#0000001f"
                          minWidth="90px"
                          width="auto"
                          px={1}
                          flex={1}
                          visibility={str?.isHidden ? 'hidden' : ''}
                        >
                          <TextTemplate>{str.label}</TextTemplate>
                        </Box>
                      ))}
                      {bodyContent()}
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </>
          ) : (
            <Box px={2}>
              <Box p={1} width="100%" minHeight="150px" bgcolor="var(--light)" borderRadius={2}>
                <Box
                  flex={1}
                  minHeight="130px"
                  height="170px"
                  maxHeight="170px"
                  bgcolor="white"
                  borderRadius={2}
                >
                  {!isStoreLocation && (
                    <Grid
                      textAlign="center"
                      lineHeight={1.1}
                      fontSize="0.85rem"
                      color="black"
                      pt={2}
                      fontWeight={600}
                    >
                      Item Name
                    </Grid>
                  )}
                  <Grid
                    container
                    gap={1}
                    px={1.1}
                    pt={1}
                    display="flex"
                    flexDirection="row"
                    flexWrap="nowrap"
                    alignItems="flex-start"
                    paddingTop={isStoreLocation && '2.3rem'}
                  >
                    <Grid item>
                      <Grid container gap={0.5}>
                        <Box
                          className="company-logo"
                          p={template === 1 ? 3.5 : 4}
                          backgroundColor="gray"
                          mr={1}
                          display="flex"
                          alignItems="center"
                        >
                          <Box color="white">Logo</Box>
                        </Box>
                        {[1, 4, 7].some((_) => _ === template) &&
                          bottomStrings.map((str, index) => (
                            <GridItem item key={index} visibility={str?.isHidden ? 'hidden' : ''}>
                              <TextTemplate>{str.label}</TextTemplate>
                            </GridItem>
                          ))}
                      </Grid>
                    </Grid>
                    {bodyContent()}
                  </Grid>
                </Box>
              </Box>
            </Box>
          )}
        </Stack>

        {/* Footer */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          px={2}
          pt={1}
          mt={5}
          borderTop="1px solid var(--lighter)"
          spacing={2}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography color="var(--gray) !important" fontSize="16px">
              Dimension:
            </Typography>
            <Select
              classNamePrefix="react-select"
              closeMenuOnSelect
              options={sizeOptions}
              value={{
                value: `${p.printSize.width}x${p.printSize.height}`,
                label: `${p.printSize.width}x${p.printSize.height}`,
              }}
              styles={{
                container: (base) => ({
                  ...base,
                  width: 150,
                }),
              }}
              onChange={handleSizeChange}
            />
          </Stack>
          <CopytStyledButton
            variant="contained"
            color="primary"
            sx={{width: 140}}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              p?.setBarcodeTemplates({
                topStrings,
                bottomStrings,
                printSize: p.printSize,
                template,
              })
              p?.onGenerateBarcodesClicked(e, false)
            }}
          >
            Submit
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlBarcodeTemplate
